import Vue from 'vue'
import Vuex from 'vuex'

import common from './modules/common'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer(val) {
    return {
      common: {
        userInfs: val.common.userInfs,
        user: val.common.user,
		isLogin:val.common.isLogin,
		func:val.common.func,
		config:val.common.config,
		orderDetail:val.common.orderDetail,
      }

    }
  }
})
const store = new Vuex.Store({
  strict: false,
  state: {},
  getters: {},
  mutations:{},
  actions:{},
  modules: {
    common,
  },
  plugins: [vuexLocal.plugin]

})

export default store;
