<template>
	<div class="index-page" v-if="!isLoading">
		<div class="empty-notic" v-if="isEmpty">[暂无直播]</div>
		<template v-else>
			<div class="live-box" @click="cancelFullScreen">
				<div class="video-box" :class="{ hide: limitTime == 0 }" id="videoBox"></div>
				<div class="live-status" v-if="isPlayEnd">
					<div>[直播结束]</div>
				</div>
				<template v-else>
					<div class="live-status" v-if="limitTime < 0">
						<!-- <div>[敬请期待]</div> -->
					</div>
					<div class="live-status" v-if="0 <= limitTime && !isPlaying ">
						<div class="view-btn" @click="palyVideo">立即观看</div>
					</div>
				</template>

				<div class="vidoe-title" v-if="!isPlaying">{{ videoTitle }}</div>
			</div>

			<div class="handle-box">
				<span>互动</span>
				<span class="time-down" v-if="limitTime < 0">
					直播倒计时：
					<span class="time">{{ timeDown }}</span>
				</span>
				<span class="full-screen" @click="fullScreen">全屏</span>
			</div>
			<div class="" v-if="5 <= tuisongTime && isswitch == 1&& dqtime<xsendtime">
				<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false" vertical>
					<van-swipe-item v-for="(item) in virtualData" :key="item.id"><van-icon name="volume-o" />
						<span> {{item.virtualinfo}} </span></van-swipe-item>
				</van-swipe>
			</div>
			<div class="content">
				<ul class="mc-word">
					<li v-for="(words, index) in mcWords" :key="index">
						<span>主持人：</span>
						{{ words }}
					</li>
				</ul>

				<div class="good-box" @click="goProducts" v-if="0 <= tuisongTime && isswitch == 1&& dqtime<xsendtime">
					<!-- <div class="good-box" @click="goProducts" > -->
					<img :src="goods" alt="" />
				</div>
			</div>
			<div class="fixed-box">
				<input class="text" placeholder="禁言中" :disabled="true" type="text" />
				<span class="btn">发送</span>
			</div>
		</template>
	</div>
	<div class="loading-box" v-else>数据加载中...</div>
</template>

<script>
	import {
		formatTime,
		getUrlParams
	} from '@/utils/public.js';
	import ShareImpl from '@/utils/wx.js';
	import DPlayer from 'dplayer';
	export default {
		name: 'indexPage',
		watch: {
			limitTime: function(val) {
				if (val < 0) {
					this.timeDown = this.formatTime(Math.abs(val));
				}
			}
		},
		data() {
			return {
				isEmpty: false,
				isLoading: true,
				isPlaying: false,
				isFullScreen: false,
				isPlayEnd: false,
				isswitch: 0,
				dplayerObj: null,
				goods: require('@/assets/image/products/products-s.png'),
				limitTime: 10,
				liveStatus: 1,
				vidoeInfs: {},
				timeDown: '',
				tuisongTime: 0,
				xsendtime:0,//
				dqtime:0,
				shareConfigs: {},
				mcWords: ['欢迎各位家长进入直播间'],
				videoTitle: '',
				titleData: '',
				virtualData: [],
				yTitle:'专家团队·高考志愿填报服务'
			};
		},

		created() {
			this.getShareConfig();
			setInterval(() => {
				this.limitTime = ++this.limitTime;
				this.tuisongTime = ++this.tuisongTime;
				this.dqtime = ++this.dqtime;
			}, 1000);
		},
		mounted() {},
		activated() {
			this.initPage();
		},
		methods: {
			formatTime,
			getUrlParams,
			ShareImpl,
			
			getShareConfig() {
				let url = window.location.href.split('#')[0];
				this.$api
					.getShareConfig({
						url: url
					})
					.then((res) => {
						let resData = res.data;
						this.shareConfigs = resData;
					});
			},
			goBuy() {
				this.$router.push({
					path: '/buy',
					query: {
						type: 1,
						index: this.$route.query.index
					}
				});
			},
			goProducts() {
				this.$router.push({
					path: '/products',
					query: {
						type: 1,
						index: this.$route.query.index,
						title: this.yTitle
					}
				});
			},
			cancelFullScreen() {
				if (this.isFullScreen) {
					this.dplayerObj.fullScreen.cancel('browser');
				}
			},
			fullScreen() {
				if (this.isPlaying) {
					this.isFullScreen = true;
					this.dplayerObj.fullScreen.request('browser');
				}
			},
			palyVideo() {
				this.isPlaying = true;
				this.dplayerObj.seek(this.limitTime);
				this.dplayerObj.play();
			},
			initVideo() {
				let that = this;
				let videoDom = window.document.getElementById(`videoBox`);
				if (videoDom) {
					let opt = {
						url: this.vidoeInfs.videofile,
						pic: this.vidoeInfs.videoimage
					};
					this.dplayerObj = new DPlayer({
						container: videoDom,
						autoplay: false,
						video: opt,
						theme: '#20a287'
					});
					this.dplayerObj.on('ended', function() {
						that.isPlayEnd = true;
					});
				}
			},
			// 获取订单信息
			getVirtual() {
				this.$api.getVirtual().then((res) => {
					this.virtualData = res.data;
				})
			},

			initPage() {
				this.isEmpty = false;
				this.isLoading = true;
				this.isPlaying = false;
				this.isFullScreen = false;
				this.isPlayEnd = false;
				this.dplayerObj = null;
				this.$api
					.getInfos({
						index: this.getUrlParams(window.location.href).index
					})
					.then((res) => {
						this.isLoading = false;
						let resData = res.data;
						document.title = res.data.name;
						if (!resData) {
							this.isEmpty = true;
							return false;
						}
						let url = window.location.href;
						let option = {
							shareTitle: resData.wxsharetitle,
							shareDesc: resData.wxsharecont,
							shareUrl: url,
							shareImg: `https://aixuebang-1310518323.file.myqcloud.com${resData.wxshareimage}`
						};
						this.isswitch = resData.isswitch;
						this.xsendtime = resData.xsendtime;
						this.dqtime = resData.dqtime;
						this.ShareImpl(this.shareConfigs, option);
						resData.videofile = `https://aixuebang-1310518323.file.myqcloud.com${resData.videofile}`;
						resData.videoimage = `https://aixuebang-1310518323.file.myqcloud.com${resData.videoimage}`;
						this.vidoeInfs = resData;
						this.limitTime = resData.kbsjc;
						this.tuisongTime = resData.tssjc;
						this.videoTitle = resData.name;
						if (Number(resData.videosc) < Number(resData.kbsjc)) {
							this.isPlayEnd = true;
						}
						this.$nextTick(() => {
							this.initVideo();
						});
					});
				this.getVirtual();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.my-swipe {
		height: 30px;
	}

	.my-swipe .van-swipe-item {
		color: #d58640;
		font-size: 14px;
		line-height: 30px;
		background-color: #fefbea;
		padding: 0 15px;
	}

	.vidoe-title {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 10px;
		font-size: 13px;
		color: #fff;
		background: rgba(0, 0, 0, 0.5);
	}

	.mc-word {
		list-style: none;
		font-size: 12px;
		color: #333;
		margin-bottom: 15px;

		span {
			color: red;
		}
	}

	.empty-notic {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		color: #333;
	}

	.loading-box {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 1000;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
	}

	.good-box {
		width: 100%;

		img {
			width: 100%;
		}
	}

	.fixed-box {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 5px 15px;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #fff;
		box-shadow: 0 0 5px #ccc;
		font-size: 20px;

		.text {
			flex: 1;
			height: 40px;
			padding: 0 15px;
			border-radius: 20px;
			border: 0;
			background: #e3e3e3;
		}

		.btn {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 40px;
			width: 50px;
			background: #3dc5a6;
			font-size: 13px;
			color: #fff;
			border-radius: 8px;
			margin-left: 10px;
		}
	}

	.content {
		flex: 1;
		width: 100%;
		padding: 15px 15px 75px 15px;
	}

	.handle-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 40px;
		padding: 0 10px;
		background: #fff;
		font-size: 13px;
		color: #333;
		box-shadow: 0 0 3px #ccc;

		.full-screen {
			background: #ed5700;
			padding: 3px 6px;
			border-radius: 5px;
			color: #fff;
		}
	}

	.time-down {
		display: flex;
		align-items: center;
		font-size: 12px;

		.time {
			font-size: 16px;
			color: #ed5700;
		}
	}

	.live-box {
		position: relative;

		.live-status {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			z-index: 10000;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 14px;
			color: #fff;
			background: rgba(0, 0, 0, 0.5);
			letter-spacing: 5px;

			.view-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100px;
				height: 35px;
				background: #ed5700;
				border-radius: 8px;
			}
		}
	}

	.video-box {
		position: relative;
	}

	.index-page {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.hide {
		&:after {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			content: '';
			z-index: 1000;
		}
	}

	::v-deep .dplayer-menu {
		display: none !important;
	}

	::v-deep .dplayer-controller {
		display: none !important;
	}

	::v-deep .dplayer-mobile-play {
		display: none !important;
	}

	::v-deep .dplayer-notice-list {
		display: none !important;
	}

	::v-deep .dplayer-controller-mask {
		display: none !important;
	}
</style>