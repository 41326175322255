export function formatTime(time) {
  time = parseInt(time);
  let hour = 0,
    minute = 0,
    second = 0;
  hour = parseInt(time / 3600);
  minute = parseInt((time - hour * 3600) / 60);
  second = time - hour * 3600 - minute * 60;
  hour = hour < 10 ? `0${hour}` : hour;
  minute = minute < 10 ? `0${minute}` : minute;
  second = second < 10 ? `0${second}` : second;
  return `${hour}:${minute}:${second}`;
}
export function getUrlParams(url) {
  // 通过 ? 分割获取后面的参数字符串
  let urlStr = url.split("?")[1];
  // 创建空对象存储参数
  if (urlStr) {
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split("&");
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split("=");
      obj[arr[0]] = arr[1];
    }
    return obj;
  } else {
    return "";
  }
}

export function checkPhone(tel) {
  let telReg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
  if (!tel) {
    return false;
  }
  if (!telReg.test(tel)) {
    return false;
  } else {
    return true;
  }
}