import axios from "axios"; //引入
import Utils from "@/utils/utils";
let baseURL = process.env.NODE_ENV == "development" ? "/api" : "https://liveapi.jingdian985.com/api";

//这一步的目的是判断出当前是开发环境还是生成环境，方法不止一种，达到目的就行
// if(process.env.NODE_ENV=="development"){
//   baseURL=process.env.BASE_URL+'/api'
// }else{
//   baseURL=process.env.BASE_URL
// }

let config = {
  baseURL: baseURL,
  timeout: 30000, //设置最大请求时间
};
const _axios = axios.create(config);

/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
  (config) => {
    const token = Utils.getToken();
    token && (config.headers.Authorization = "Bearer " + token);
    // config.headers.common["meedu-platform"] = "H5";
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
_axios.interceptors.response.use(
  (response) => {
    let code = response.data.code;
    if (code !== 0) {
      // api请求返回错误
      if (code === 401) {
        Utils.clearToken();
        window.location.href = Utils.getHost();
      }
      return Promise.reject(response);
    } else {
      return Promise.resolve(response);
    }
  },
  // 当http的状态码非2xx
  (error) => {
    let httpCode = error.response.status;
    if (httpCode === 401) {
      // 未登录
    } else if (httpCode === 403) {
      // 无权限
    } else {
      // 其他错误
    }
    return Promise.reject(error.response);
  }
);


//封装post,get方法
const http = {
  get(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        params,
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        method: "GET",
      })
        .then((res) => {
          resolve(res.data);
          return res;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  post(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data: params,
        // headers: { "Content-Type": "application/json;charset=UTF-8" },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data);
          return res;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  put(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data: params,
        method: "PUT",
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }
};

export default http;
