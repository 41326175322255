import https from "../http";
const common = {
	getInfos: function(params) {
		return https.get("/zhibo/getzhiboinfo", params);
	},
	getGoodinfo: function(params) {
		return https.get("/addons/zhibo/good/getgoodinfo", params);
	},
	getVirtual: function(params) {
		return https.get("/Virtual/getvirtual", params);
	},
	getOrders: function(params) {
		return https.get("/v2/member/orders", params);
	},
	getShareConfig: function(params) {
		return https.post(
			"/addons/zhibo/wxshare",
			params
		);
	},
	submitInfs: function(params) {
		return https.post(
			"https://liveapi.jingdian985.com/index.php/api/order/setorderInfo",
			params
		);
	},
	orderPay: function(params) {
		return https.post(
			"/addons/zhibo/order/pay",
			params
		);
	},
	orderOrderpay: function(params) {
		return https.post("/addons/zhibo/order/orderpay", params);
	},
	getusersig: function(params) {
		return https.post("/addons/zhibo/user/getusersig", params);
	},
	getpayinfo: function(params) {
		return https.get("/addons/zhibo/tencent/payinfo", params);
	},
	Auth: {
		SmsLogin(params) {
			return https.post("/v2/login/mobile", params);
		},
		PasswordLogin(params) {
			return https.post("/v2/login/password", params);
		},
		WechatMiniLogin(params) {
			return https.post("/v2/wechat/mini/login", params);
		},
		WechatMiniLoginState(params) {
			return https.post("/v2/login/wechatMini", params);
		},
		WechatMiniLoginMobile(params) {
			return https.post("/v2/login/wechatMiniMobile", params);
		},
		CodeLogin(params) {
			return https.post(`/v3/auth/login/code`, params);
		},
		CodeBind(params) {
			return https.post(`/v3/member/socialite/bindWithCode`, params);
		},
		DestroyUser(params) {
			return https.post(`/v3/member/destroy`, params);
		},
	},
	Member: {
		Detail() {
			return https.get("/v2/member/detail");
		},
		UploadAvatar(params) {
			return https.post("/v2/member/detail/avatar", params);
		},
		NicknameChange(params) {
			return https.post("/v2/member/detail/nickname", params);
		},
		AvatarChange(params) {
			return https.post("/v2/member/avatar", params);
		},
		MobileVerify(params) {
			return https.post("/v2/member/verify", params);
		},
		MobileChange(params) {
			return https.put("/v2/member/mobile", params);
		},
		NewMobile(params) {
			return https.post("/v2/member/detail/mobile", params);
		},
		CodeBindMobile(params) {
			return https.post("/v3/auth/register/withSocialite", params);
		},
		PasswordChange(params) {
			return https.post("/v2/member/detail/password", params);
		},
		WechatLogin() {
			return https.get("/v2/login/wechatScan");
		},
		WechatBind() {
			return https.get("/v2/member/wechatScan/bind");
		},
		CheckWechatLogin(params) {
			return https.get("/v2/login/wechatScan/query", params);
		},

		Profile() {
			return https.get("/v2/member/profile");
		},
		ProfileSave(params) {
			return https.post("/v2/member/profile", params);
		},
		Credit1Records(params) {
			return https.get("/v2/member/credit1Records", params);
		},
		PromoCode() {
			return https.get("/v2/member/promoCode");
		},
		PromoCodeCreate() {
			return https.post("/v2/member/promoCode");
		},
		Withdraw(params) {
			return https.post("/v2/member/withdraw", params);
		},
		InviteUsers(params) {
			return https.get("/v2/member/inviteUsers", params);
		},
		WithdrawRecords(params) {
			return https.get("/v2/member/withdrawRecords", params);
		},
		InviteBalanceRecords(params) {
			return https.get("/v2/member/inviteBalanceRecords", params);
		},
		Messages(params) {
			return https.get("/v2/member/messages", params);
		},
		ReadMessage(id) {
			return https.get("/v2/member/notificationMarkAsRead/" + id);
		},
		Orders(params) {
			return https.get("/v2/member/orders", params);
		},
		Courses(params) {
			return https.get("/v2/member/courses", params);
		},
		UnReadNum() {
			return https.get("/v2/member/unreadNotificationCount");
		},
		NewCourses(params) {
			return https.get("/v3/member/courses", params);
		},
		CoursesCollects(params) {
			return https.get("/v3/member/courses/like", params);
		},
		TecentFaceVerify(params) {
			return https.post("/v3/member/tencent/faceVerify", params);
		},
		TecentFaceVerifyQuery(params) {
			return https.get("/v3/member/tencent/faceVerify", params);
		},

	},
	User: {
		Detail() {
			return https.get(`/v2/member/detail`);
		},
		Orders(params) {
			return https.get("/v2/user/orders", params);
		},
		Courses(params) {
			return https.get("/v2/member/courses", params);
		},
		LikeCourses(params) {
			return https.get("/v2/member/courses/like", params);
		},
	},
	Other: {
		Captcha() {
			return https.get("/v2/captcha/image");
		},
		SendSms(params) {
			return https.post("/v2/captcha/sms", params);
		},
		Config() {
			return https.get("/v2/other/config");
		},
	},
};

export default common;