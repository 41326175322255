import wx from "weixin-js-sdk";
const ShareImpl = function(config, option) {
	wx.config({
		debug: false, //是否开启debug
		appId: config.appId, //你的appid
		timestamp: config.timestamp, //时间戳
		nonceStr: config.nonceStr, //随机字符串
		signature: config.signature, //秘钥（秘钥生成规则请看我另一篇文章（php获取微信AccessToken，生成秘钥））
		jsApiList: [
			//请求的api接口列表，微信的，详情去看微信官方文档
			"updateAppMessageShareData",
			"updateTimelineShareData",
		],
	});
	wx.checkJsApi({
		jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
		success: function(res) {
			// 以键值对的形式返回，可用的api值true，不可用为false
			// 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
			console.log(res);
		},
	});
	wx.ready(function() {
		//如果wx.config配置正常，会自动走此处
		//自定义“分享给朋友”及“分享到QQ”按钮的分享内容
		wx.updateAppMessageShareData({
			title: option.shareTitle, //分享的标题
			desc: option.shareDesc, //分享的描述信息
			link: option.shareUrl, //分享的url
			imgUrl: option.shareImg, //分享的图片
			success: function() {
			},
		});
		//imgUrl: option.shareImg, //分享的图片
		//自定义“分享到朋友圈”及“分享到QQ空间”按钮的分

		wx.updateTimelineShareData({
			title: option.shareTitle, //分享的标题
			desc: option.shareDesc, //分享的描述信息
			link: option.shareUrl, //分享的url
			imgUrl: option.shareImg, //分享的图片
			success: function() {},
		});
	});
	wx.error(function(res) {
		//如果wx.config配置失败,会走到此处
		console.log("error", res);
	});
};

export default ShareImpl; //将此方法导出