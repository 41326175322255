import Utils from '@/utils/utils';
const state = {
	tabActive: 0,
	detailActive: 0,
	userInfs: {},
	refreshToken: false,
	flowDetail: {},
	orderDetail: {},
	titleData: '',
	isLogin: false,
	user: {},
	config: null,
	func: {
		// 邀请码功能[该功能是meedu自带功能，如果您使用了三级分销插件可关闭该功能]
		promoCode: true,
		// 直播[需购买插件：https://addons.meedu.vip/addons/24/Zhibo]
		live: false,
		// 电子书[需购买插件：https://addons.meedu.vip/addons/17/MeeduBooks]
		book: false,
		// 考试[需购买插件：https://addons.meedu.vip/addons/36/Paper]
		paper: false,
		// 练习
		practice: true,
		// 模拟考试
		mockPaper: true,
		// 错题本
		wrongBook: false,
		// 话题[需购买插件：https://addons.meedu.vip/addons/16/MeeduTopics]
		topic: false,
		// 兑换码[需购买插件：https://addons.meedu.vip/addons/45/CodeExchanger]
		codeExchanger: false,
		// H5微信公众号授权登录
		selfWechatLogin: true,
		// H5-QQ登录
		qqLogin: false,
		// 三级分销[需购买插件：https://addons.meedu.vip/addons/12/MultiLevelShare]
		share: false,
		// 随机拍照[需购买插件：https://addons.meedu.vip/addons/41/Snapshot]
		snapshort: false,
		// 大小班课[需购买插件：https://addons.meedu.vip/addons/35/XiaoBanKe]
		ke: false,
	},
}
const getters = {
	getTabActive(state) {
		return state.tabActive
	},
	getUserInfs(state) {
		return state.userInfs
	},
	getFunc(state) {
		return state.userInfs
	},
	getConfig(state) {
		return state.config
	},
	getIsLogin(state) {
		return state.isLogin
	},
	getRefreshToken(state) {
		return state.refreshToken
	},
	getDetailActive(state) {
		return state.detailActive
	},
	getFlowDetail(state) {
		return state.flowDetail
	},
	getOrderDetail(state) {
		return state.orderDetail
	},

}
const actions = {

}
const mutations = {
	setTabActive(state, data) {
		state.tabActive = data
	},
	setUserInfs(state, data) {
		state.userInfs = data
	},
	setRefreshToken(state, data) {
		state.refreshToken = data
	},
	setDetailActive(state, data) {
		state.detailActive = data
	},

	setFlowDetail(state, data) {
		state.flowDetail = data
	},
	setOrderDetail(state, data) {
		state.orderDetail = data
	},
	submitLogin(state, user) {
		state.user = user;
		state.userInfs = user
		state.isLogin = true;
	},
	setConfig(state, config) {
		state.config = config;
	},
	logout(state) {
		state.user = null;
		state.isLogin = false;
		Utils.clearToken();
	},

}
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}