import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import Message from "vue-m-message";
import ShareImpl from "./utils/wx";
import utils from "./utils/utils";
import router from "./router";
import axios from "axios";
import api from "@/api/index.js";
import {
	Picker,
	Area,
	Notify,
	Icon,
	ActionSheet,
	Swipe,
	Search,
	List,
	Step,
	Steps,
	SwipeItem,
	Toast,
	Image,
	CountDown,
	Tab,
	Tabs,
	Popup
} from "vant";
import "vue-m-message/dist/index.css";
import 'vant/es/toast/style';
import './assets/commonality.css';

Vue.use(Picker);
Vue.use(Area);
Vue.use(Notify);
Vue.use(Icon);
Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(List);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Toast);
Vue.use(Image);
Vue.use(CountDown);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popup);
Vue.use(Step);
Vue.use(Steps);
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
Vue.prototype.$utils = utils;
Vue.prototype.$message = Message;
Vue.prototype.ShareImpl = ShareImpl;
Vue.config.productionTip = false;
new Vue({
	store,
	router,
	render: (h) => h(App),
}).$mount("#app");
Vue.mixin({
  methods: {
    goBack() {
      if (window.history.length <= 2) {
        this.$router.push({ name: "Index" });
      } else {
        this.$router.go(-1);
      }
    },
  },
});