<template>
  <div class="buy-page">
    <div class="banner-box">
      <ul class="banner-list">
        <li
          v-for="(banner, index) in bannerImgs"
          :style="`zIndex:${bannerImgs.length - index}`"
          :key="index"
          :class="{ active: activeIndex == index }"
        >
          <img :src="banner" alt="" />
        </li>
      </ul>
      <img :src="buyImg" class="buy-img" />
    </div>
    <ul class="text-img" :class="{ hasBtn: pageType }">
      <li v-for="(img, index) in textImgs" :key="index">
        <img :src="img" alt="" />
      </li>
    </ul>

    <div class="buy-now" v-if="pageType">
      <span @click="buyStatus = true">立即购买</span>
    </div>

    <div class="infor-form" v-if="buyStatus" :style="`height:${viewHeight}`">
      <div class="order-title">
        <span>订单信息</span>
        <span class="close-btn" @click="buyStatus = false"></span>
      </div>

      <div class="order-infs">
        <div class="order-img">
          <img :src="orderInfs.img" />
        </div>
        <div class="infs-item">
          <div class="order-name">{{ orderInfs.name }}</div>
          <div class="order-price">
            优惠价：<span>¥{{ orderInfs.price }}</span>
          </div>
          <div class="order-price">
            原&nbsp;&nbsp;&nbsp;价：<span class="through-style"
              >¥{{ orderInfs.defaultPrice }}</span
            >
          </div>
        </div>
      </div>

      <div class="order-form">
        <div class="order-item">
          <div class="order-label"><span>*</span>收货人姓名</div>
          <input type="text" placeholder="收货人姓名" v-model="formData.username" />
        </div>
        <div class="order-item">
          <div class="order-label"><span>*</span>收货人电话</div>
          <input
            type="text"
            placeholder="收货人电话"
            v-model="formData.userphone"
          />
        </div>
        <div class="order-item">
          <div class="order-label"><span>*</span>选择地区</div>
          <input
            type="text"
            readonly
            placeholder="请选择地区"
            @click="showPicker('area')"
            v-model="formData.usercity"
          />
        </div>
        <div class="order-item">
          <div class="order-label"><span>*</span>详细地址</div>
          <textarea
            type="text"
            placeholder="请输入详细收货地址，具体到门牌号"
            v-model="formData.useraddress"
            cols="3"
          ></textarea>
        </div>
        <div class="order-item">
          <div class="order-label"><span>*</span>就读学校</div>
          <input
            type="text"
            placeholder="请填写学校名称"
            v-model="formData.schoolname"
          />
        </div>
        <div class="order-item">
          <div class="order-label"><span>*</span>就读年级</div>
          <input
            type="text"
            readonly
            placeholder="请选择当前就读年级"
            v-model="formData.nianji"
            @click="showPicker('grade')"
          />
        </div>
      </div>

      <div class="pay-btn" @click="checkFormAddSubmit">去支付</div>
    </div>

    <div class="picker-layer" v-if="showPickerLayer">
      <van-area
        title="选择地区"
        :area-list="areaList"
        @confirm="onConfirmArea"
        @cancel="onCancel"
        v-if="showAreaPicker"
      />

      <van-picker
        title="就读年级"
        show-toolbar
        :columns="gardeData"
        @confirm="onConfirmGrade"
        @cancel="onCancel"
        v-if="showGradePicker"
      />
    </div>

    <div class="submit-requesting" v-if="isRequesting">数据提交中...</div>
  </div>
</template>

<script>
import ShareImpl from "@/utils/wx.js";
import { areaList } from "@vant/area-data";
import { checkPhone } from "@/utils/public.js";
export default {
  name: "buyPage",
  data() {
    let _viewHeight = document.documentElement.clientHeight;
    console.log(_viewHeight)
    let texts = [];
    for (let i = 0; i < 36; i++) {
      texts[i] = require(`@/assets/image/detail/tu_${i}.jpg`);
    }
    let banners = [];
    for (let i = 0; i < 5; i++) {
      banners[i] = require(`@/assets/image/detail/banner_${i}.png`);
    }
    return {
      isRequesting:false,
      buyStatus: false,
      showPickerLayer: false,
      showAreaPicker: false,
      showGradePicker: false,
      buyImg: require(`@/assets/image/detail/jiage.png`),
      textImgs: texts,
      bannerImgs: banners,
      activeIndex: 0,
      pageType: 1,
      areaList: areaList,
      viewHeight:_viewHeight + 'px',
      gardeData: ["六年级", "七年级", "八年级", "九年级"],
      orderInfs: {
        img: require("@/assets/image/good.png"),
        name: "精准学1对1个性化自主学习系统",
        price: "2980.00",
        defaultPrice: "3680.00",
      },
      formData: {
        username: "",
        userphone: "",
        usercity: "",
        useraddress: "",
        schoolname: "",
        nianji: "",
      },
    };
  },
  created() {
    this.pageType = this.$route.query.type || 0;
  },
  activated() {
    this.getShareConfig();
  },
  mounted() {
    let len = this.bannerImgs.length - 1;
    setInterval(() => {
      if (this.activeIndex < len) {
        this.activeIndex = ++this.activeIndex;
      } else {
        this.activeIndex = 0;
      }
    }, 3000);
  },
  methods: {
    ShareImpl,
    checkPhone,
    checkFormAddSubmit() {
      this.$notify.clear();
      let formData = this.formData;
      if (!formData.username) {
        this.$notify({
          type: "warning",
          message: "请输入收货人姓名",
        });
        return false;
      } else if (!this.checkPhone(formData.userphone)) {
        this.$notify({
          type: "warning",
          message: "请输入正确格式收货人电话",
        });
        return false;
      } else if (!formData.usercity) {
        this.$notify({
          type: "warning",
          message: "请选择地区",
        });
        return false;
      } else if (!formData.useraddress) {
        this.$notify({
          type: "warning",
          message: "请输入详细地址",
        });
        return false;
      } else if (!formData.schoolname) {
        this.$notify({
          type: "warning",
          message: "请填写学校名称",
        });
        return false;
      } else if (!formData.nianji) {
        this.$notify({
          type: "warning",
          message: "请选择当前就读年级",
        });
        return false;
      }
      this.isRequesting=true;
      this.$api.submitInfs({
        data:formData
      }).then(res=>{
        this.isRequesting=false;
        console.log(res)
        let changeUrl = res.data.url
        window.location.href = changeUrl
      })
     
    },
    showPicker(str) {
      this.showPickerLayer = true;
      if (str == "area") {
        this.showAreaPicker = true;
      } else if (str == "grade") {
        this.showGradePicker = true;
      }
    },
    onCancel() {
      this.showPickerLayer = false;
      this.showAreaPicker = false;
      this.showGradePicker = false;
    },
    onConfirmGrade(val) {
      this.onCancel();
      this.formData.nianji = val;
    },
    onConfirmArea(val) {
      this.onCancel();
      console.log(val);
      this.formData.usercity = `${val[0].name},${val[1].name},${val[2].name}`;
    },
    getShareConfig() {
      let url = window.location.href;
      this.$api
        .getShareConfig({
          url: url,
        })
        .then((res) => {
          let resData = res.data;
          this.ShareImpl(resData, {
            shareTitle: "精准学1对1个性化自主学习系统",
            shareDesc: "让中国更多的父母享受与孩子一起成长的快乐。",
            shareUrl: url,
            shareImg:
              "https://aixuebang-1310518323.cos.ap-shanghai.myqcloud.com/logo/logo.png",
          });
        });
    },
    goBack() {
      this.$router.push({
        path: "/",
        query: {
          index: this.$route.query.index,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-requesting{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 400;
  background: rgba(255,255,255,.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.picker-layer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 300;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: flex-end;
  ::v-deep .van-picker {
    width: 100%;
  }
}
.infor-form {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background: #fff;
  overflow: auto;
  padding: 60px 0;
  .order-form {
    padding: 20px;
    .order-item {
      display: flex;
      align-items: center;
      border-bottom: 1px #cbcbcb solid;
      padding: 10px 0;
      .order-label {
        width: 100px;
        font-size: 13px;
        color: #333;
        span {
          color: red;
          padding-right: 3px;
        }
      }
      input,
      textarea {
        flex: 1;
        height: 30px;
        border: 0;
        outline: none;
        font-size: 14px;
        font-family: inherit;
      }
      textarea {
        height: 60px;
        resize: none;
      }
    }
  }
  .pay-btn {
    position: absolute;
    left: 10px;
    right:10px;
    //bottom: 10px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 25px;
    font-size: 17px;
    color: #fff;
    background: #1cd1ad;
    &:active {
      opacity: 0.8;
    }
  }
  .order-infs {
    padding: 20px;
    display: flex;
    .infs-item {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      font-size: 14px;
      .order-price {
        span {
          color: red;
          font-weight: 600;
        }
        .through-style {
          color: #333;
          text-decoration: line-through;
        }
      }
    }
    .order-img {
      width: 100px;
      margin-right: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .order-title {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #259982;
    color: #fff;
    font-size: 14px;
    .close-btn {
      position: absolute;
      right: 15px;
      top: 50%;
      width: 25px;
      height: 25px;
      border: 1px #fff solid;
      border-radius: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      &::after,
      &::before {
        position: absolute;
        width: 1px;
        height: 15px;
        content: "";
        background: #fff;
        top: 50%;
        left: 50%;
      }
      &::after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
.buy-now {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 40px;
    background: #259982;
    font-size: 13px;
    color: #fff;
    border-radius: 20px;
    margin: 0 15px;
    &:active {
      transition: all ease-in-out 0.3s;
      opacity: 0.8;
    }
  }
  .back {
    background: #ff6632;
  }
}
.banner-list {
  width: 100%;
  position: absolute;
  li {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 0.5;
    transition: opacity ease-in-out 0.3s;
    img {
      width: 100%;
    }
  }
  .active {
    opacity: 1;
    z-index: 100 !important;
  }
}
.banner-box {
  position: relative;
  height: 300px;
  .buy-img {
    left: 50%;
    bottom: -0.2rem;
    transform: translate(-50%, 0);
    position: absolute;
    z-index: 200;
    width: 372px;
  }
}
.text-img {
  width: 100%;
  li {
    width: 100%;
    img {
      width: 100%;
      vertical-align: top;
    }
  }
}
.hasBtn {
  margin-bottom: 60px;
}
.buy-page {
  width: 100vw;
  height: 100vh;
  background: #fef5e3;
  overflow: auto;
  scroll-behavior: smooth;
}
</style>