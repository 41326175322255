import Vue from "vue";
import VueRouter from "vue-router";
import index from "@/views/liveJ/index.vue"
import buy from "@/views/liveJ/buy.vue"
import utils from "@/utils/utils";
Vue.use(VueRouter);

const routes = [{
		path: '/liveJ',
		name: 'liveJ',
		component: index,
	}, {
		path: '/',
		name: 'liveBroadcast',
		meta: {
		  title: "京点教育",
		  auth: true,
		},
		component: () => import("@/views/liveBroadcast/index.vue"),
	}, {
		path: '/orderList',
		name: 'orderList',
		component: () => import("@/views/liveBroadcast/orderlist.vue"),
	}, {
		path: '/orderDetails',
		name: 'orderDetails',
		component: () => import("@/views/liveBroadcast/orderdetails.vue"),
	}, {
		path: '/goodsDetails',
		name: 'goodsDetails',
		component: () => import("@/views/liveBroadcast/goodsdetails.vue"),
	},
	{
		path: '/buy',
		name: 'buy',
		meta: {
			title: "精准学1对1个性化自主学习系统",
		},
		component: buy
	}, {
		path: '/products',
		name: 'products',
		component: () => import("@/views/liveJ/products.vue"),
	}, {
		path: '/detailPage',
		name: 'detailPage',
		meta: {
			title: "精准学1对1个性化自主学习系统",
		},
		component: () => import("@/views/introduce/detailPage.vue"),
	}, {
		path: '/ask',
		name: 'ask',
		component: () => import("@/views/introduce/ask.vue"),
	}, {
		path: '/study',
		name: 'study',
		component: () => import("@/views/introduce/study.vue"),
	}, {
		path: '/registrationService',
		name: 'registrationService',
		component: () => import("@/views/introduce/registrationService.vue"),
	}, {
		path: '/highTest',
		name: 'highTest',
		component: () => import("@/views/introduce/highTest.vue"),
	},{
		path: '/annualMeeting',
		name: 'annualMeeting',
		component: () => import("@/views/introduce/annualMeeting.vue"),
	}, {
		path: '/paymentProducts',
		name: 'paymentProducts',
		component: () => import("@/views/payment/products.vue"),
	}, {
		path: '/member',
		name: 'Me',
		meta: {
			title: "个人中心",
		},
		component: () => import("@/views/member/index.vue"),
	}, 		
	{
	  path: "/member/order",
	  name: "MemberOrder",
	  meta: {
	    title: "我的订单",
	    auth: true,
	  },
	  component: () => import("../views/member/order.vue"),
	},
	{
	  path: "/member/setting",
	  name: "MemberSetting",
	  meta: {
	    title: "关于平台",
	  },
	  component: () => import("../views/member/setting.vue"),
	},
	{
	  path: "/member/profile",
	  name: "MemberProfile",
	  meta: {
	    title: "个人资料",
	    auth: true,
	  },
	  component: () => import("../views/member/profile.vue"),
	},
	{
	  path: "/member/password",
	  name: "ChangePassword",
	  meta: {
	    title: "重置密码",
	  },
	  component: () => import("../views/member/password.vue"),
	},
	{
	  path: "/member/mobile",
	  name: "ChangeMobile",
	  meta: {
	    title: "绑定手机号",
	  },
	  component: () => import("../views/member/mobile.vue"),
	},
	{
	  path: "/member/mobileVerify",
	  name: "MobileVerify",
	  meta: {
	    title: "验证原手机号",
	  },
	  component: () => import("../views/member/mobileVerify.vue"),
	},
	
	{
		path: "/login",
		name: "login",
		meta: {
			title: "快捷登录/注册",
		},
		component: () => import("@/views/auth/login.vue"),
	},
	{
		path: "/login-password",
		name: "loginPassword",
		meta: {
			title: "密码登录",
		},
		component: () => import("@/views/auth/login-password.vue"),
	},
	{
		path: "/login-error",
		name: "loginError",
		meta: {
			title: "错误",
		},
		component: () => import("@/views/auth/login-error.vue"),
	},
	{
		path: "/bind-mobile",
		name: "bindMobile",
		meta: {
			title: "绑定手机号",
		},
		component: () => import("@/views/auth/bind-mobile.vue"),
	},
	{
		path: "/code-bind-mobile",
		name: "codeBindMobile",
		meta: {
			title: "绑定手机号",
		},
		component: () => import("@/views/auth/code-bind-mobile.vue"),
	},
	{
		path: "/auth/faceSuccess",
		name: "faceSuccesss",
		meta: {
			title: "实名认证",
		},
		component: () => import("@/views/auth/faceSuccess.vue"),
	},
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: function(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			if (from.meta.keepAlive) {
				from.meta.savedPosition = document.body.scrollTop;
			}
			return {
				x: 0,
				y: to.meta.savedPosition || 0
			};
		}
	},
});

//判断是否登录
router.beforeEach(function(to, from, next) {
	let token = utils.getToken();
	if (to.meta.title) {
	  document.title = to.meta.title;
	}
	// if (to.path == "/annualMeeting"|| to.path == "/buy"||to.path == "/detailPage") {
	// 	next();
	// }else if (to.meta.auth === true) {
	//   let token = utils.getToken();
	//   if (!token) {
	//     next({
	//       name: "login",
	//       query: {
	//         url: window.location.href,
	//       },
	//     });
	//     return;
	//   }
	// }
	next()
	// if (to.path != "/login"&&to.path != "/login-password") {
	// 	//通过查看token判断是否登录
	// 	if (token && token != "") {
	// 		next(); //表示已经登录
	// 	} else {
	// 		//next可以传递一个路由对象作为参数 表示需要跳转到的页面
	// 		next({
	// 			name: "login",
	// 			query: {
	// 			  url: window.location.href,
	// 			},
	// 		});
	// 	}
	// } else {
	// 	//表示不需要登录
	// 	next(); //继续往后走
	// }
});
export default router;